import styled from "styled-components";

export const ModalFormVideoContainer = styled.div`
    border-radius: 8px;
    height: fit-content;
    display: flex;  
    flex-direction: column;
    width: 456px;
    padding: 32px;
    gap: 24px;
    justify-content: center;
    align-items: center;
    border-radius: 16px;

.divisor {
    width: 100%;
    display: flex;
    height: 3px;
    align-items: center;
    justify-content: center;
    margin-bottom: 16px;
}

@media (max-width: 500px) {
    border-radius: 8px;
    height: fit-content;
    display: flex;  
    flex-direction: column;
    align-self: center;
    max-width: 340px;
    padding: 24px;
    gap: 16px;
    justify-content: center;
    align-items: center;
    border-radius: 16px;

    > .divisor {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 8px;
    }
}
`;

export const ModalTitle = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 16px; 
    
    >  div h2 {
      font-size: 24px;
      line-height: 130%;
      font-weight: 600;
      color: #393939;
      text-align: center;
    };

    > div span {
      color: #27adff;
      text-align: center;
      font-size: 24px;
      line-height: 100%;
      font-weight: 600;
      width: 100%;
      display: block;
      margin-bottom: 16px;
    }

    > p {
      font-size: 14px;
      line-height: 140%;
      font-weight: normal;
      color: #575757;
      text-align: center;
      margin: 0;
    }

    @media( max-width: 500px ){
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 8px;

      > div h2 {
        font-size: 18px;
        line-height: 130%;
        font-weight: 600;
        color: #393939;
        text-align: center;
      }

      > div span {
        color: #27adff;
        text-align: center;
        font-size: 18px;
        line-height: 130%;
        font-weight: 600;
        display: inline;
        margin-bottom: 8px;
      }
        p {
        font-size: 12px;
        line-height: 140%;
        font-weight: normal;
        color: #575757;
        text-align: center;
        margin: 0;
      }
      
    }
`;

export const FormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const FormContainer = styled.form`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 24px;

  label{
  display: flex;
  flex-direction: column;
  font-size: 12px;
  line-height: 140%;
  color: #575757;

    > input{
    margin: 8px 0;
    padding: 12px 16px;
    border-radius: 4px;
    background-color: #F0F3F8;
    border: none;
    font-size: 14px;
    line-height: 140%;
    color: #575757;

      &:focus{
      border: none;
      outline: 0;
      }
    }

    > .errorMessage{
      font-size: 12px;
      line-height: 140%;
      color: #ff0037;
      margin-bottom: 0;
    }
  }

  .privacyPolice {
    font-size: 12px;
    line-height: 140%;
    color: #575757;
    margin-bottom: 0;

      > a{
          color: #575757;
          text-decoration: underline;
          cursor: pointer;
        };
  }

  @media( max-width: 500px ){
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 8px;
    flex-shrink: 1;

    label{
      display: flex;
      flex-direction: column;
      font-size: 12px;
      line-height: 140%;
      color: #575757;

      > input{
          margin: 8px 0;
          padding: 12px 16px;
          border-radius: 4px;
          background-color: #F0F3F8;
          border: none;
          font-size: 12px;
          line-height: 140%;
          color: #575757;
        }
    }
      .privacyPolice {
        margin: 0px 0px 16px 0;
      }
  }
`;

export const ButtonsContainer = styled.div`
  display: flex;
  gap: 16px;
  justify-content: center;
  align-items: center;

    .buttonPrimary{
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      width: 168px;
      height: 45px;
      background-color: #27adff;
      color: white;
      border-radius: 48px;
      border: none;
      font-weight: 500;
      font-size: 14px;
    }

    .buttonSecondary{
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      width: 168px;
      height: 45px;
      background-color:transparent;
      color: #575757;
      border-radius: 48px;
      border: 1px solid #575757;
      font-weight: 500;
      font-size: 14px;
    }

    .buttonPrimary:hover{
      filter: brightness(0.8);
    }

    .buttonSecondary:hover{
      background-color:#575757;
      color: white;
    }


    @media( max-width: 500px ){
      flex-direction: column-reverse;
      gap: 8px;

      > .buttonPrimary, .buttonSecondary{
        width: 100%;
      }
    }
`;