import { IFrameContainer } from './styles'

export default function VideoModal(){
    return(
        <>
			<IFrameContainer>
			<iframe 
				src="https://www.youtube.com/embed/nsv-RgkK-mw?autoplay=1&mute=0&controls=1&loop=1&modestbranding=1&rel=0&showinfo=0"
				title="YouTube video player"
				allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
				referrerPolicy="strict-origin-when-cross-origin" 
				allowFullScreen>
			</iframe>
            </IFrameContainer>
        </>
    )
};