import { ModalFormVideoContainer, ModalTitle, FormContainer, ButtonsContainer, FormWrapper } from "./styles";

import Rectangle from '../../../../assets/Rectangle.png'

import * as zod from 'zod'
import { zodResolver } from '@hookform/resolvers/zod'
import { useForm } from 'react-hook-form';

interface VideoModalFormProps {
    onSubmit: () => void;
    onRequestClose: () => void; 
  }

export const VideoFormSchema = zod.object({
  name: zod.string().min(1, { message: "Por favor, digite o seu nome." }),
  email: zod
    .string()
    .email({ message: "Por favor, digite um e-mail válido." })
    .refine(
      (email) => {
        const blockedProviders = /^(?!.*@(gmail\.com|outlook\.com|msn\.com|msn\.com.br|hotmail\.com|yahoo\.com|bol\.com.br)$).*/;
        return blockedProviders.test(email);
      },
      { message: "Por favor, use um e-mail corporativo" }
    ),
});
  
  type VideoFormInputs = zod.infer<typeof VideoFormSchema>

export default function VideoModalForm({ onSubmit, onRequestClose }: VideoModalFormProps) {

    const { register, handleSubmit, formState: { errors } } = useForm<VideoFormInputs>({
        resolver: zodResolver(VideoFormSchema),
    });

    async function handleFormSubmit(data: VideoFormInputs) {

        const { name, email } = data;

        try {
            const response = await fetch(process.env.REACT_APP_STRIPE_PUBLIC_BASE_URL + '/api/newsletters', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ data: { name, email } }),
            })
        } catch (err) {
            console.log(err)
        }
        onSubmit(); 
    }

    return(
        <ModalFormVideoContainer>
			<ModalTitle>
				<div>
				    <h2>Veja como o Bounty simplifica
				    <span> a gestão de despesas</span>
					</h2>
				</div>
				<div className='divisor'>
					<img src={Rectangle} alt="" />
				</div>
					<p>Entenda o funcionamento ao assistir nossa demo.</p>
			</ModalTitle>

			<FormWrapper>
                <FormContainer onSubmit={handleSubmit(handleFormSubmit)}> 
                    <label htmlFor="name">Nome:
                        <input
                            type="text"
                            id="name"
                            placeholder="Digite seu nome"
                            {...register('name')}
                        />
                        {errors.name && <p className='errorMessage'>{errors.name.message}</p>}
                    </label>
                    <label htmlFor="email">E-mail corporativo:
                        <input
                            type="email"
                            id="email"
                            placeholder="nome@empresa.com.br"
                            {...register('email')}
                        />
                        {errors.email && <p className='errorMessage'>{errors.email.message}</p>}
                    </label>
                    <p className='privacyPolice'>
                        Ao informar seus dados, você concorda com a nosso <a href="https://app.cartaobounty.com.br/privacy" target='_blank'>Aviso de privacidade</a>
                    </p>

                    <ButtonsContainer>
                        <button type="button" className='buttonSecondary' onClick={onRequestClose}>
                            Sair
                        </button>
                        <button type="submit" className='buttonPrimary'>
                            Ver demo
                        </button>
                    </ButtonsContainer>
                </FormContainer>
		    </FormWrapper>
		</ModalFormVideoContainer>
    )
};