import styled from "styled-components";

export const IFrameContainer = styled.div`
  position: relative;
  width: 96vw;
  max-width: 1080px;
  margin: 0 auto 1rem;
  height: 0px;
  padding-bottom: 56.25%;
  overflow: hidden;
  border-radius: 16px;


  iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
  padding: 1rem 1rem 0 1rem;
}
`;